import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useGet from "../../../hooks/common/useGet";
import { Table } from "../../common/Table";
import { beautify, numberFormat } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { AccountCode } from "./BalanceSheetReports";
import { LinkText } from "../../common/Buttons";

const ChartsOfAccountsReport = () => {
  const {
    loadData: getChartsOfAccountsReport,
    data: chartsOfAccountsReportData,
    loading: chartsOfAccountsReportLoading,
  } = useGet();

  const [filters, setFilters] = useState({
    start_date: null,
    end_date: new Date().toISOString().split("T")[0],
  });

  const [expandedAccounts, setExpandedAccounts] = useState<Set<string>>(
    new Set(),
  );

  useEffect(() => {
    getChartsOfAccountsReport({
      api: "accountings/charts/report",
      params: { ...filters },
    });
  }, [filters]);

  useEffect(() => {
    if (chartsOfAccountsReportData?.result) {
      const allAccountIds = new Set<string>();

      const collectIds = (accounts: any[]) => {
        accounts.forEach((account) => {
          if (account.id) {
            allAccountIds.add(account.id);
          }
          if (account.children && account.children.length > 0) {
            collectIds(account.children);
          }
        });
      };

      collectIds(chartsOfAccountsReportData.result);
      setExpandedAccounts(allAccountIds);
    }
  }, [chartsOfAccountsReportData]);

  const onAccountNameClick = (account: any) => {
    const newExpanded = new Set(expandedAccounts);
    if (newExpanded.has(account.id)) {
      newExpanded.delete(account.id);
    } else {
      newExpanded.add(account.id);
    }
    setExpandedAccounts(newExpanded);
  };

  const renderNestedRows = (data: any[], depth: number = 0) =>
    data.flatMap((item) => [
      {
        ...item,
        account_code: (
          <LinkWrapper onClick={() => onAccountNameClick(item)}>
            <AccountCode
              style={{
                justifyContent: "start",
                marginLeft: `${depth * 20}px`,
                fontSize: "90%",
              }}
            >
              <span>
                {item.children?.length > 0 && (
                  <span style={{ fontSize: "10px" }}>
                    {expandedAccounts.has(item.id) ? "▼ " : "▶ "}
                  </span>
                )}
                {item?.general_ledger_code || item?.account_code}
              </span>
            </AccountCode>
          </LinkWrapper>
        ),
        account_name: (
          <LinkText
            to={`/transactions/${item?.id}`}
            style={{
              justifyContent: "start",
              marginLeft: `${depth * 20}px`,
              fontSize: "90%",
            }}
          >
            {beautify(item?.account_name)}
          </LinkText>
        ),
        amount: (
          <div style={{ textAlign: "right", fontSize: "90%" }}>
            {numberFormat(item?.amount)}
          </div>
        ),
      },
      ...(expandedAccounts.has(item.id)
        ? renderNestedRows(item.children || [], depth + 1)
        : []),
    ]);

  const chartsOfAccountsTableData = (data: any) => renderNestedRows(data);

  const chartsOfAccountsTableColumns = () => [
    { id: "account_code", header: "Account Code" },
    { id: "account_name", header: "Account Name" },
    // { id: "credit", header: "Credit" },
    // { id: "debit", header: "Debit" },
    { id: "amount", header: "Amount" },
  ];

  return (
    <Wrapper>
      <div className="container flexColumn gap20">
        <HeaderWrapper>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to="/" className="breadcrumb-item">
                    Home
                  </Link>
                ),
              },
              {
                title: "Charts of Accounts Report",
              },
            ]}
          />
          {/* <ExportButton
            text="Export All"
            onClick={handleDownloadAll}
            isDownloading={isDownloading}
          /> */}
        </HeaderWrapper>
        <div className="flexColumn gap20">
          <Table
            count={chartsOfAccountsReportData?.count || 0}
            tableTitle="Charts of Accounts Report"
            tableColumns={chartsOfAccountsTableColumns()}
            tableData={
              chartsOfAccountsReportData
                ? chartsOfAccountsTableData(
                    chartsOfAccountsReportData?.result || [],
                  )
                : []
            }
            filters={filters}
            onFilterChange={setFilters}
            noEmptyText={false}
            loading={chartsOfAccountsReportLoading}
            //   tableHeaderItem={
            //     <ExportButton
            //       text="Export"
            //       title="Export Table Data"
            //       isDownloading={isDownloadingFiltered}
            //       onClick={() => handleDownloadFiltered()}
            //     />
            //   }
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ChartsOfAccountsReport;

const Wrapper = styled.div`
  padding: 0 20px 20px 20px;
  @media (max-width: 960px) {
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const LinkWrapper = styled.span`
  cursor: pointer;
  &:hover {
    color: var(--selected-color) !important;
    text-decoration: underline;
  }
`;
