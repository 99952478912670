import { CSSProperties } from "react";
import { ButtonProps } from "../../types/common";
import { colors } from "../../utils/constants";
import { Button, Tooltip } from "antd";
import styled from "styled-components";
import { LoaderIcon } from "react-hot-toast";
import { DownloadOutline, OpenOutline } from "@raresail/react-ionicons";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";

type SubmitButtonProp = {
  submitting: boolean;
  text?: string;
  disabled?: boolean;
  submitText?: string;
  style?: CSSProperties;
};

export const SubmitButton = ({
  submitting,
  text,
  submitText,
  disabled,
  style,
  ...rest
}: SubmitButtonProp) => {
  return (
    <button
      type="submit"
      className="btn btn-success primary-btn"
      disabled={submitting || disabled}
      style={{
        ...style,
        backgroundColor: colors.THEME_COLOR,
        color: "white",
        marginBottom: 10,
        marginTop: 10,
      }}
      {...rest}
    >
      {submitting ? submitText || "Submitting" : text || "Submit"}
    </button>
  );
};

export const PrimaryButton = (props: ButtonProps) => {
  const { title, loaderTitle, loading, type, style, ...rest } = props;

  return (
    <button
      className="btn mb-4 btn-primary btn-theme"
      style={{ ...style }}
      type={type}
      disabled={loading}
      {...rest}
    >
      {loading ? loaderTitle : title}
    </button>
  );
};

export const ExportButton = (props: any) => {
  const { text, onClick, isDownloading, title } = props;
  return (
    <Tooltip title={title || "Download Report"}>
      <DownloadButton onClick={onClick} disabled={isDownloading}>
        {isDownloading ? (
          <LoaderIcon />
        ) : (
          <FlexWrapper>
            {text || "Export"}
            <DownloadOutline fontSize={16} />
          </FlexWrapper>
        )}
      </DownloadButton>
    </Tooltip>
  );
};

const DownloadButton = styled(Button)`
  padding: 5px 10px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

interface PrintButtonProps {
  contentRef: React.RefObject<HTMLDivElement>;
  isPrinting?: boolean;
  text?: string;
  documentTitle?: string;
  pageScale?: number;
  orientation?: "portrait" | "landscape" | "auto";
}

const PrintButton: React.FC<PrintButtonProps> = ({
  contentRef,
  isPrinting = false,
  text = "Print",
  documentTitle = "Report",
  pageScale = 1,
  orientation = "auto",
}) => {
  const handlePrint = useReactToPrint({
    contentRef,
    pageStyle: `
      @page {
        size: auto;
        margin: 10px;
        scale: ${pageScale};
        size: ${orientation};
      }
    `,
    documentTitle,
  });
  return (
    <Button
      icon={<PrinterOutlined />}
      onClick={() => handlePrint()}
      loading={isPrinting}
    >
      {text}
    </Button>
  );
};

export default PrintButton;

export const LinkText = ({ children, ...props }: any) => {
  return (
    <LinkTextWrapper
      className="flexRow gap5"
      style={{ alignItems: "center", flexWrap: "nowrap" }}
    >
      <LinkWrapper {...props}>{children}</LinkWrapper>
      <OpenOutline className="text-link-icon" />
    </LinkTextWrapper>
  );
};

const LinkTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: inherit;
  gap: 5px;
  .text-link-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover .text-link-icon {
    opacity: 1;
  }
  &:hover a {
    text-decoration: underline !important;
    color: inherit !important;
  }
`;

const LinkWrapper = styled(Link)`
  color: inherit;
`;
