import { Tabs } from "antd";

const GenericTabView = ({ tabs }) => {
  return (
    <Tabs
      defaultActiveKey="1"
      items={tabs.map((val, i) => {
        const { title, Icon, component } = val || {};
        return {
          label: (
            <span>
              {Icon}
              {title}
            </span>
          ),
          key: i,
          children: <div>{component}</div>,
        };
      })}
    />
  );
};
export default GenericTabView;
