import ReactPaginate from "react-paginate";
import { TFormValues, TTableColumn } from "../../types/common";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableFoot,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { TableFilter } from "./TableFilter";
import styled from "styled-components";
import { beautify, getStatusColor, numberFormat } from "../../utils/helpers";
import { Tag } from "antd";
import { EmptyWrapper } from "./Elements";
import Loader from "./Loader";

type TTableProps = {
  loading: boolean;
  count: number;
  tableTitle?: string;
  tableFooter?: any;
  tableTotals?: Record<string, any>;
  tableColumns: TTableColumn[];
  tableData: any[];
  hideFilters?: boolean;
  // filters?: TFilter;
  // onFilterChange?(prop: TFilter): void;
  filters?: any;
  onFilterChange?: any;
  updateFormValues?(obj: TFormValues): void;
  tableStyle?: any;
  status?: any;
  noEmptyText?: boolean;
  showPagination?: boolean;
  tableHeaderItem?: any;
  pageSize?: number;
};

export const Table = (props: TTableProps) => {
  const {
    tableData,
    tableColumns,
    tableFooter,
    tableTotals,
    tableTitle,
    loading,
    count,
    filters,
    hideFilters,
    tableStyle,
    onFilterChange,
    status,
    noEmptyText = true,
    showPagination = true,
    tableHeaderItem,
    pageSize = 10,
  } = props;

  const onChange = (key: string, value: string | number) => {
    const updatedFilters = { ...filters };
    delete updatedFilters.page;
    if (value === "" || value === null || value === undefined) {
      delete updatedFilters[key];
      if (onFilterChange) onFilterChange(updatedFilters);
      return;
    } else {
      if (onFilterChange) onFilterChange({ ...updatedFilters, [key]: value });
    }
  };

  function getCount() {
    if (count > 1000) return `${(count / 1000).toFixed(0)}K`;
    return count;
  }

  // paginated table records
  const startValue = ((filters?.page || 1) - 1) * pageSize + 1;
  const endValue = ((filters?.page || 1) - 1) * pageSize + tableData?.length;
  const counter_text = (
    <>
      Showing of{" "}
      <b>
        {numberFormat(startValue)} - {numberFormat(endValue)}
      </b>{" "}
      of {getCount() || 0} items
    </>
  );

  return (
    <TableWrapper className="">
      <TitleWrapper className="whiteBg flexCenter spaceCenter">
        <div className="flex gap10">
          <span className="capitalize bold">{tableTitle}</span>
          {status && (
            <Tag className="capitalize" color={getStatusColor(status)}>
              {beautify(status)}
            </Tag>
          )}
        </div>
        {/* COUNTER TEXT */}
        <FlexWrapper>
          {count > 0 && <span className="font13">{counter_text}</span>}
          {tableHeaderItem && <span className="font13">{tableHeaderItem}</span>}
        </FlexWrapper>
      </TitleWrapper>

      <div className="table-wrapper" style={{ ...tableStyle }}>
        <div className="responsive-table">
          <CTable style={{ ...tableStyle }} striped>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell style={{ fontSize: "85%" }} scope="col">
                  #
                </CTableHeaderCell>
                {tableColumns?.map((item) => (
                  <CTableHeaderCell
                    className={item?.noWrap ? "no-wrap" : ""}
                    style={{ fontSize: "85%" }}
                    scope="col"
                  >
                    <span className="capitalize">{item.header}</span>
                  </CTableHeaderCell>
                ))}
              </CTableRow>

              {hideFilters ? null : (
                <CTableRow>
                  <CTableHeaderCell
                    style={{ fontSize: "85%" }}
                    scope="col"
                  ></CTableHeaderCell>

                  {tableColumns?.map((row, i) => {
                    return row.filter ? (
                      <CTableHeaderCell
                        key={i}
                        style={{
                          whiteSpace: "nowrap",
                          border: "1px solid #ddd",
                        }}
                      >
                        <TableFilter
                          name={row.filter?.key ? row.filter?.key : row.id}
                          onChange={onChange}
                          updateFormValues={onFilterChange}
                          updateFilterValues={onFilterChange}
                          filters={filters}
                          selectOptions={row.filter?.options}
                          {...row.filter}
                        />
                      </CTableHeaderCell>
                    ) : (
                      <CTableHeaderCell />
                    );
                  })}
                </CTableRow>
              )}
            </CTableHead>
            <CTableBody>
              {loading ? (
                <CTableRow>
                  <CTableHeaderCell
                    scope="row"
                    colSpan={tableColumns?.length + 1}
                    className="text-center"
                    style={{ whiteSpace: "nowrap", fontWeight: "normal" }}
                  >
                    <Loader />
                  </CTableHeaderCell>
                </CTableRow>
              ) : tableData?.length ? (
                tableData.map((item, idx) => (
                  <CTableRow>
                    <CTableHeaderCell style={{ fontSize: "85%" }} scope="row">
                      {item?.index || startValue + idx}
                    </CTableHeaderCell>
                    {tableColumns.map((column) => (
                      <CTableDataCell
                        style={{ fontSize: "85%" }}
                        className={column?.noWrap ? "no-wrap" : ""}
                      >
                        {item[column.id] ||
                          (noEmptyText && <EmptyWrapper>Empty</EmptyWrapper>)}
                      </CTableDataCell>
                    ))}
                  </CTableRow>
                ))
              ) : (
                <CTableRow>
                  <CTableHeaderCell
                    scope="row"
                    colSpan={tableColumns?.length + 1}
                    className="text-center font14"
                    style={{ whiteSpace: "nowrap", fontWeight: "normal" }}
                  >
                    No data available
                  </CTableHeaderCell>
                </CTableRow>
              )}
            </CTableBody>
            {tableTotals && Object.keys(tableTotals).length > 0 && (
              <CTableFoot>
                <CTableRow>
                  <CTableHeaderCell style={{ fontSize: "85%" }} scope="col">
                    Totals
                  </CTableHeaderCell>
                  {tableColumns?.map((column) => (
                    <CTableHeaderCell
                      key={column.id}
                      className={column?.noWrap ? "no-wrap" : ""}
                      style={{ fontSize: "85%" }}
                      scope="col"
                    >
                      <span className="capitalize bold">
                        {tableTotals[column.id]}
                      </span>
                    </CTableHeaderCell>
                  ))}
                </CTableRow>
              </CTableFoot>
            )}
            {tableFooter && (
              <CTableFoot>
                <CTableRow>
                  <CTableHeaderCell style={{ fontSize: "85%" }} scope="col">
                    #
                  </CTableHeaderCell>
                  {tableColumns?.map((column) => (
                    <CTableHeaderCell
                      className={column?.noWrap ? "no-wrap" : ""}
                      style={{ fontSize: "85%" }}
                      scope="col"
                    >
                      <span className="capitalize">
                        {tableFooter?.[column.id]}
                      </span>
                    </CTableHeaderCell>
                  ))}
                </CTableRow>
              </CTableFoot>
            )}
          </CTable>
        </div>
      </div>

      <div className="kv-panel-after" />
      <div className="panel-footer">
        {showPagination && (
          <div className="kv-panel-pager">
            {loading ||
              (tableData?.length > 0 && count ? (
                <ReactPaginate
                  breakLabel="..."
                  previousLabel={<span>«</span>}
                  onPageChange={({ selected }) =>
                    onChange("page", selected + 1)
                  }
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(count / pageSize)}
                  marginPagesDisplayed={3}
                  nextLabel={<span>»</span>}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination justify-content-end"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                  forcePage={(filters?.page || 1) - 1}
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                />
              ) : null)}
          </div>
        )}
        <div className="clearfix" />
      </div>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ddd;

  .panel-footer {
    padding: 0 20px;
  }
`;

const TitleWrapper = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;
