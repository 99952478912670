export const URL_LOAN_APPLICATIONS = "/register/loan_application";
export const URL_LOAN_APPLICATIONS_RESCHEDULE =
  "/register/loan_application_reschedule";
export const URL_LOAN_APPLICATIONS_DRAFTS = "/register/loan_application_drafts";
export const URL_LEDGERS = "/register/ledgers";
export const URL_INSTALMENTS = "/register/instalments";
export const URL_PURCHASE_ORDERS = "/register/purchase_order";
export const URL_INVENTORY = "/register/inventory";
export const URL_PRODUCTS = "/register/products";
export const URL_PAYROLL = "/register/payrolls";
export const URL_EXPENSES = "/register/expenses";
export const URL_REQUISITIONS = "/register/requisitions";
export const URL_OTHER_INCOME = "/register/other_income";
export const URL_PAYMENTS_REPORT = "/register/payments_report";
export const URL_LOAN_REPAYMENTS_REPORT = "/register/loan_repayments_report";
export const URL_LOAN_APPLICATIONS_REPORT = "/reports/loan-applications-report";
export const URL_DEBTORS_AGEING_REPORT = "/register/debtors_ageing_report";
export const URL_LEDGER_REPORT = "/register/ledger_report";
// USERS
export const URL_USERS = "/users";
export const URL_ROLES = "/roles";

// CONFIGURABLES
export const URL_CONFIGURABLES = "/configurables";
export const URL_INTEREST_CONFIGURABLES = "/interest_configurables";
export const URL_PERIOD_CONFIGURABLES = "/period_configurables";
export const URL_FEES_CONFIGURABLES = "/fees_schedule_configurables";
export const URL_SUPPLIER_CONFIGURABLES = "/supplier_configurables";
export const URL_CHARTS_CONFIGURABLES = "/charts_of_accounts_configurables";
export const URL_ALLOWANCE_TYPES_CONFIGURABLES =
  "/allowance_types_configurables";
export const URL_PAYMENT_MODES_CONFIGURABLES = "/payment_modes_configurables";

// MASTER DATA
export const URL_MASTER_DATA = "/master_data";
export const URL_DISTRICTS = "/districts";
export const URL_COUNTIES = "/counties";
export const URL_SUB_COUNTIES = "/sub_counties";
export const URL_PARISHES = "/parishes";
export const URL_VILLAGES = "/villages";

// AUDIT ACTIVITY
export const URL_AUDIT_ACTIVITY = "/audit_activity";

// OFFICES
export const URL_DEPARTMENTS = "/departments";

// ORGANISATION
export const URL_ORGANISATIONS = "/organisations";

// PASSWORD RESET
export const URL_PASSWORD_RESET = "/password_reset";
export const URL_SETTINGS = "/settings";

// LOGIN
export const URL_LOGIN = "/login";
export const URL_REGISTER = "/register";

// FEED
export const URL_FEES = "/fees";
