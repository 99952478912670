import React, { isValidElement, useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import useGet from "../../../hooks/common/useGet";
import { Table } from "../../common/Table";
import {
  beautify,
  downloadThroughAnchorLink,
  numberFormat,
  returnObject,
} from "../../../utils/helpers";
import GenericTabView from "../../layouts/GenericTabView";
import { Link } from "react-router-dom";
import { Breadcrumb, DatePicker, Tooltip } from "antd";
import { ExportButton, LinkText } from "../../common/Buttons";
import { BASE_API_URL } from "../../../config";
import {
  API_CASH_FLOW_EXPORT,
  API_FINANCIAL_POSITION_EXPORT,
  API_PROFIT_AND_LOSS_EXPORT,
} from "../../../utils/apis";

interface ReportItem {
  account_name: string;
  amount: number;
  general_ledger_code?: string;
  account_code?: string;
  id?: string;
}

type TReport = "cash_flow" | "financial_position" | "profit_and_loss";
interface FormattedReportItem {
  account_code: React.ReactNode;
  account_name?: React.ReactNode;
  amount?: React.ReactNode;
  children?: FormattedReportItem[];
  id?: string;
}

interface TotalConfig {
  keys: string[];
  name: string;
  after?: string;
  subtract?: boolean;
}
interface ProcessReportDataOptions {
  orderedKeys?: string[];
  parentLevel?: number;
  totals?: TotalConfig[];
  collapsedKeys?: string[];
  expandedKeys?: Set<string>;
}

const BalanceSheetReports = () => {
  const {
    loadData: getFinancialPosition,
    data: financialPositionData,
    loading: financialPositionLoading,
  } = useGet();
  const {
    loadData: getCashFlow,
    data: cashFlowData,
    loading: cashFlowLoading,
  } = useGet();
  const {
    loadData: getProfitLoss,
    data: profitLossData,
    loading: profitLossLoading,
  } = useGet();
  useGet();
  const [filters, setFilters] = useState({
    start_date: null,
    end_date: new Date().toISOString().split("T")[0],
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const { RangePicker } = DatePicker;
  const [expandedKeys, setExpandedKeys] = useState<Set<string>>(new Set());

  useEffect(() => {
    getFinancialPosition({
      api: "reports/financial-position/",
      params: filters,
    });
    getCashFlow({ api: "reports/cash-flow-statement/", params: filters });
    getProfitLoss({
      api: "reports/profit-and-loss-statement/",
      params: filters,
    });
  }, [filters]);

  const handleDownload = (report: TReport) => {
    let api,
      fileName = "";
    switch (report) {
      case "cash_flow":
        api = `${BASE_API_URL}${API_CASH_FLOW_EXPORT}`;
        fileName = "cash_flow_statement.xlsx";
        break;
      case "financial_position":
        api = `${BASE_API_URL}${API_FINANCIAL_POSITION_EXPORT}`;
        fileName = "financial_position.xlsx";
        break;
      case "profit_and_loss":
        api = `${BASE_API_URL}${API_PROFIT_AND_LOSS_EXPORT}`;
        fileName = "profit_and_loss.xlsx";
        break;
    }
    setIsDownloading(true);
    downloadThroughAnchorLink(api, fileName, filters).finally(() => {
      setIsDownloading(false);
    });
  };

  const financialPositionTableColumns = () => [
    { id: "account_code", header: "Account Code" },
    { id: "account_name", header: "Account Name" },
    { id: "amount", header: "Amount" },
  ];

  const transformData = (key: string, items: any[]) => {
    let totalAmount = 0;
    const childData = items.map((item: any) => {
      totalAmount += item?.amount;
      return {
        account_code: (
          <AccountCode>
            {item?.general_ledger_code || item?.account_code}
          </AccountCode>
        ),
        account_name: item?.account_name,
        amount: numberFormat(item?.amount),
      };
    });
    return [
      {
        account_code: <b>{beautify(key)}</b>,
      },
      ...childData,
      ...returnObject(totalAmount !== 0, [
        {
          account_code: <b>{beautify(`Total ${key}`)}</b>,
          amount: <b>{numberFormat(totalAmount)}</b>,
        },
      ]),
    ];
  };

  const getTableData = (data: any) => {
    if (!data) return [];
    const dataKeys = Object.keys(data);
    return [...dataKeys.flatMap((key) => [...transformData(key, data[key])])];
  };

  const onToggleExpand = (key: string) => {
    const newKeys = new Set(expandedKeys);
    if (newKeys.has(key)) {
      newKeys.delete(key);
    } else {
      newKeys.add(key);
    }
    setExpandedKeys(newKeys);
  };

  const formatReportItem = ({
    item,
    boldKeys = [],
    collapsedKeys = [],
    expandedKeys = new Set(),
    level = 0,
  }: {
    item: ReportItem;
    boldKeys?: string[];
    collapsedKeys?: string[];
    expandedKeys?: Set<string>;
    level?: number;
  }): FormattedReportItem => {
    const checkBold = (accountName: string, value: React.ReactNode) =>
      boldKeys.includes(accountName) ? <b>{value}</b> : value;
    console.log(level);
    return {
      account_code: collapsedKeys?.includes(item.account_name) ? (
        <ClickableSpan onClick={() => onToggleExpand(item.account_name)}>
          <AccountCode>
            {checkBold(
              item.account_name,
              item.general_ledger_code || item.account_code,
            )}
            <span style={{ marginLeft: 5, fontSize: "0.8em" }}>
              {expandedKeys?.has(item?.account_name) ? "▼" : "▶"}
            </span>
          </AccountCode>
        </ClickableSpan>
      ) : (
        <AccountCode>
          {checkBold(
            item.account_name,
            item.general_ledger_code || item.account_code,
          )}
        </AccountCode>
      ),
      account_name: (
        <LinkText
          to={`/transactions/${item?.id}`}
          style={{
            justifyContent: "start",
            marginLeft: `${(level - 1) * 20}px`,
          }}
        >
          {checkBold(item.account_name, item.account_name)}
        </LinkText>
      ),
      amount: (
        <LinkText to={`/transactions/${item?.id}`}>
          {checkBold(item.account_name, numberFormat(item.amount))}
        </LinkText>
      ),
    };
  };

  const createTotalWithTooltip = (total: TotalConfig, value: number) => {
    const operation = total.subtract ? "-" : "+";
    const tooltipText = `${total.keys.join(` ${operation} `)}`;

    return {
      account_code: <b>{total.name || "Totals"}</b>,
      amount: (
        <Tooltip title={tooltipText}>
          <b>{numberFormat(value)}</b>,
        </Tooltip>
      ),
    };
  };

  const calculateTotals = (totals: any, totalsValues: any, data: any) => {
    if (!totals) return;

    totals.forEach((total) => {
      // Get the first key as base value
      const findAndSumAmount = (items: any[], searchKey: string): number => {
        return items.reduce((sum, item) => {
          if (item.account_name === searchKey) {
            return sum + (item.amount || 0);
          }
          if (item.children) {
            return sum + findAndSumAmount(item.children, searchKey);
          }
          return sum;
        }, 0);
      };

      // Handle the first key
      const [firstKey, ...remainingKeys] = total.keys;
      let totalValue =
        totalsValues[firstKey] ||
        findAndSumAmount(Object.values(data), firstKey);

      // Process remaining keys based on subtract flag
      remainingKeys.forEach((key) => {
        const keyValue =
          totalsValues[key] || findAndSumAmount(Object.values(data), key);
        if (total.subtract) {
          totalValue -= keyValue;
        } else {
          totalValue += keyValue;
        }
      });

      totalsValues[total.name] = totalValue;
    });
  };

  const processReportData = (
    data: any,
    options?: ProcessReportDataOptions,
  ): FormattedReportItem[] => {
    if (!data) return [];

    const defaultOptions: ProcessReportDataOptions = {
      orderedKeys: [],
      parentLevel: 0,
      totals: [],
      expandedKeys: new Set(),
    };
    const {
      orderedKeys,
      parentLevel,
      totals,
      collapsedKeys,
      expandedKeys,
      showTabs = false,
    } = {
      ...defaultOptions,
      ...options,
    };
    const totalsValues: { [key: string]: number } = {};

    calculateTotals(totals, totalsValues, data);

    const flattenData = (
      item: any,
      level: number = 0,
    ): FormattedReportItem[] => {
      const hasChildren = item.children && item.children.length > 0;
      const isCollapsed =
        collapsedKeys?.includes(item.account_name) &&
        !expandedKeys?.has(item.account_name);

      console.log(level);

      // Create current item row with toggle functionality
      const parentRow: FormattedReportItem = {
        account_code: (
          <LinkText to={`/transactions/${item?.id}`}>
            <b>{item.account_name}</b>,
          </LinkText>
        ),
      };
      const isParentLevel = level <= (parentLevel || 0);

      const currentRow = isParentLevel
        ? parentRow
        : formatReportItem({
            item,
            boldKeys: hasChildren ? [item.account_name] : [],
            collapsedKeys,
            expandedKeys,
            level: !showTabs ? 1 : level,
          });

      // Initialize result with current row
      const result: FormattedReportItem[] = [currentRow];

      const addTotalsRow = (result: FormattedReportItem[]) =>
        result.push({
          account_code: <b>{`Total ${item.account_name}`}</b>,
          amount: <b>{numberFormat(item.amount)}</b>,
        });

      // Process children only if not collapsed
      if (hasChildren && !isCollapsed) {
        // Order children according to orderedKeys
        const orderedChildren =
          orderedKeys && orderedKeys.length
            ? [
                // include ordered items that exist in children
                ...orderedKeys
                  .map((key) =>
                    item.children.find(
                      (child: any) => child.account_name === key,
                    ),
                  )
                  .filter(Boolean),
                //  include remaining children that aren't in orderedKeys
                ...item.children.filter(
                  (child: any) => !orderedKeys.includes(child.account_name),
                ),
              ]
            : item.children;

        const childrenRows = orderedChildren.flatMap((child: any) =>
          flattenData(child, level + 1),
        );
        result.push(...childrenRows);
        if (isParentLevel) {
          addTotalsRow(result);
        }
      } else {
        if (isParentLevel) {
          addTotalsRow(result);
        }
      }

      return result;
    };

    // Process the main data object
    const mainData = Object.entries(data).flatMap(
      ([, value]: [string, any]) => {
        return flattenData(value);
      },
    );

    const totalRows =
      totals && totals.length
        ? totals?.map((total) => ({
            account_code: <b>{total.name || "Totals"}</b>,
            amount: <b>{numberFormat(totalsValues[total.name])}</b>,
          }))
        : [];

    // If there's an 'after' property, insert the totals after the specified row
    if (totals?.length) {
      let modifiedData = [...mainData];
      const totalsToAppend: FormattedReportItem[] = [];
      totals.forEach((total) => {
        // const totalRow = {
        //   account_code: <b>{total.name || "Totals"}</b>,
        //   amount: <b>{numberFormat(totalsValues[total.name])}</b>,
        // };
        const totalRow = createTotalWithTooltip(
          total,
          totalsValues[total.name],
        );

        if (total.after) {
          const afterIndex = modifiedData.findIndex(
            (item) =>
              item?.account_name === total.after ||
              (isValidElement(item?.account_name) &&
                item?.account_name?.props?.children === total.after) ||
              (isValidElement(item?.account_code) &&
                item?.account_code?.props?.children === total.after),
          );
          if (afterIndex !== -1) {
            modifiedData = [
              ...modifiedData.slice(0, afterIndex + 1),
              totalRow,
              ...modifiedData.slice(afterIndex + 1),
            ];
          }
        } else {
          // Collect totals without 'after' to append at the end
          totalsToAppend.push(totalRow);
        }
      });
      return [...modifiedData, ...totalsToAppend];
    }

    return [...mainData, ...totalRows];
  };

  const getFinancialTableData = (data: any) => {
    if (!data) return [];

    const current_assetsConfig = {
      orderedKeys: [
        "Cash at Bank and in Hand",
        "Cash In Hand",
        "MOMO - MTN",
        "Airtel Pay",
        "Cash at Bank",
        "Stanbic Bank",
        "Accounts Receivable",
        "Loan Application Fees Receivable",
        "Boda Insurance Fees Receivable",
        "Boda Tracking Fees Receivable",
        "Loans to Others Receivable",
        "UGG654K Accounts Receivable",
        "UGG897L Accounts Receivable",
        "UGG699C Accounts Receivable",
        "UGG831J Accounts Receivable",
        "Inventory",
      ],
      collapsedKeys: ["Loans to Others Receivable"],
      totals: [
        {
          keys: ["Equity", "Liabilities"],
          name: "Total Liabilities and Equity",
        },
      ],
      parentLevel: 0,
      expandedKeys: expandedKeys,
      showTabs: true,
    };

    return processReportData(data, current_assetsConfig);
  };

  const getProfitLossTableData = (data: any) => {
    if (!data) return [];
    return processReportData(data, {
      orderedKeys: [
        "Loan Application Fees Income",
        "Boda Insurance Fees Income",
        "Boda Tracking Fees Income",
        "Income",
        "Interest Income",
      ],
      totals: [
        {
          keys: ["Income", "Cost of Goods Sold"],
          name: "Gross Profit",
          after: "Total Cost of Goods Sold",
          subtract: true,
        },
        {
          keys: ["Gross Profit", "Operating Expenses"],
          name: "Operating Profit",
          after: "Total Operating Expenses",
          subtract: true,
        },
        {
          keys: ["Operating Profit", "Finance Costs"],
          name: "Net Profit/Loss",
          subtract: true,
        },
      ],
    });
  };
  const DateFilter = () => {
    return (
      <div>
        <div className="capitalize font12 mb-1">Filter by date</div>
        <RangePicker
          onChange={(value: any) => {
            setFilters({
              start_date: value?.[0].format("YYYY-MM-DD") || null,
              end_date: value?.[1].format("YYYY-MM-DD") || null,
            });
          }}
          value={[
            filters.start_date ? dayjs(filters.start_date) : null,
            filters.end_date ? dayjs(filters.end_date) : null,
          ]}
          style={{ height: 40 }}
        />
      </div>
    );
  };
  const tabs: any[] = [
    {
      title: "Financial Positions",
      component: (
        <div className="flexColumn gap20">
          <DateFilter />
          <Table
            count={financialPositionData?.count || 0}
            tableTitle="Financial Positions"
            tableColumns={financialPositionTableColumns()}
            tableData={
              financialPositionData
                ? getFinancialTableData(financialPositionData)
                : []
            }
            noEmptyText={false}
            loading={financialPositionLoading}
            tableHeaderItem={
              <ExportButton
                text="Export"
                title="Export Table Data"
                isDownloading={isDownloading}
                onClick={() => handleDownload("financial_position")}
              />
            }
          />
        </div>
      ),
    },
    {
      title: "Profit and Loss",
      component: (
        <div className="flexColumn gap20">
          <DateFilter />
          <Table
            count={profitLossData?.count || 0}
            tableTitle="Profit and Loss"
            tableColumns={financialPositionTableColumns()}
            tableData={
              profitLossData ? getProfitLossTableData(profitLossData) : []
            }
            noEmptyText={false}
            loading={profitLossLoading}
            tableHeaderItem={
              <ExportButton
                text="Export"
                title="Export Table Data"
                isDownloading={isDownloading}
                onClick={() => handleDownload("profit_and_loss")}
              />
            }
          />
        </div>
      ),
    },
    {
      title: "Cash Flow Statement",
      component: (
        <div className="flexColumn gap20">
          <DateFilter />
          <Table
            count={cashFlowData?.count || 0}
            tableTitle="Cash Flow Statement"
            tableColumns={financialPositionTableColumns()}
            tableData={cashFlowData ? getTableData(cashFlowData) : []}
            noEmptyText={false}
            loading={cashFlowLoading}
            tableHeaderItem={
              <ExportButton
                text="Export"
                title="Export Table Data"
                isDownloading={isDownloading}
                onClick={() => handleDownload("cash_flow")}
              />
            }
          />
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      <div className="container">
        <Breadcrumb
          items={[
            {
              title: (
                <Link to="/" className="breadcrumb-item">
                  Home
                </Link>
              ),
            },
            {
              title: "Reports",
            },
          ]}
        />
        <GenericTabView tabs={tabs} />
      </div>
    </Wrapper>
  );
};

export default BalanceSheetReports;

const Wrapper = styled.div`
  padding: 0 20px 20px 20px;
  @media (max-width: 960px) {
  }
`;

export const AccountCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  font-family: "Courier New", Courier, monospace;
  font-weight: 500;
`;

const ClickableSpan = styled.span`
  cursor: pointer;
  &:hover {
    color: var(--selected-color);
    text-decoration: underline;
  }
`;
