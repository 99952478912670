import React from "react";
import {
  Add,
  Group,
  ListSharp,
  LocationOnOutlined,
  Description,
  ArrowBack,
} from "@mui/icons-material";
import {
  URL_CONFIGURABLES,
  URL_ROLES,
  URL_USERS,
  URL_MASTER_DATA,
  URL_DISTRICTS,
  URL_COUNTIES,
  URL_SUB_COUNTIES,
  URL_PARISHES,
  URL_VILLAGES,
  URL_AUDIT_ACTIVITY,
  URL_FEES,
  URL_PASSWORD_RESET,
  URL_SETTINGS,
  URL_INTEREST_CONFIGURABLES,
  URL_PERIOD_CONFIGURABLES,
  URL_FEES_CONFIGURABLES,
  URL_CHARTS_CONFIGURABLES,
  URL_SUPPLIER_CONFIGURABLES,
  URL_ALLOWANCE_TYPES_CONFIGURABLES,
  URL_DEPARTMENTS,
  URL_ORGANISATIONS,
  URL_PAYMENT_MODES_CONFIGURABLES,
} from "../utils/urls";
import { getForm, getRegister, useGetUserPermissions } from "../utils/helpers";
import {
  createAddOption,
  createRegisterOption,
} from "../components/menu/menuHelpers";
import {
  CAN_ADD_LOAN_USER,
  CAN_VIEW_SYSTEM_SETTINGS,
  CAN_EDIT_SYSTEM_SETTINGS,
  CAN_VIEW_LOAN_USER,
  CAN_ADD_ORGANISATION,
  CAN_MANAGE_CHARTS_OF_ACCOUNTS,
} from "../utils/constants";
import {
  AccessibilityOutline,
  BuildOutline,
  BusinessOutline,
  CardOutline,
  HomeOutline,
  KeyOutline,
  LogOutOutline,
  NavigateOutline,
  PeopleOutline,
  PersonOutline,
  SettingsOutline,
  ShieldCheckmarkOutline,
} from "@raresail/react-ionicons";

export const accountSettings = [
  {
    label: "Profile",
    icon: PersonOutline,
    to: URL_SETTINGS,
  },
  {
    label: "Change Password",
    icon: KeyOutline,
    to: getForm(URL_PASSWORD_RESET),
  },
  {
    label: "Logout",
    icon: LogOutOutline,
    to: "/logout",
  },
];

export const useSettings = () => {
  const permissions = useGetUserPermissions();
  const hasConfigurablesPermissions = permissions.includes(
    CAN_VIEW_SYSTEM_SETTINGS,
    CAN_EDIT_SYSTEM_SETTINGS,
  );
  return [
    {
      label: "Back To Dashboard",
      icon: <ArrowBack />,
      to: "/",
    },
    {
      label: "Account Settings",
      key: "default",
      icon: <SettingsOutline />,
      to: URL_SETTINGS,
    },
    // User Management
    {
      label: "User Management",
      icon: <PeopleOutline />,
      to: getRegister(URL_USERS),
      visible: permissions?.includes(CAN_VIEW_LOAN_USER),
      children: [
        {
          label: "Add User",
          icon: <Add />,
          to: getForm(URL_USERS),
          visible: permissions?.includes(CAN_ADD_LOAN_USER),
        },
        {
          label: "Users",
          icon: <Group />,
          to: getRegister(URL_USERS),
        },
      ],
    },
    // ROLES
    {
      label: "System Roles",
      icon: <AccessibilityOutline />,
      visible: permissions.includes(
        CAN_VIEW_SYSTEM_SETTINGS,
        CAN_EDIT_SYSTEM_SETTINGS,
      ),
      children: [
        {
          label: "New Role",
          icon: <Add />,
          to: getForm(URL_ROLES),
        },
        {
          label: "Roles Register",
          icon: <ListSharp />,
          to: getRegister(URL_ROLES),
        },
      ],
    },
    // Configurables
    {
      label: "Configurables",
      icon: <BuildOutline />,
      visible:
        hasConfigurablesPermissions ||
        permissions.includes(CAN_MANAGE_CHARTS_OF_ACCOUNTS),
      children: [
        {
          label: "Create Configurable",
          icon: <Add />,
          to: getForm(URL_CONFIGURABLES),
          visible: hasConfigurablesPermissions,
        },
        {
          label: "All Configurables",
          icon: <Description />,
          to: getRegister(URL_CONFIGURABLES),
          visible: hasConfigurablesPermissions,
        },
        {
          label: "Charts of Accounts Configurables",
          icon: <Description />,
          to: getRegister(URL_CHARTS_CONFIGURABLES),
          visible:
            hasConfigurablesPermissions ||
            permissions.includes(CAN_MANAGE_CHARTS_OF_ACCOUNTS),
        },
        {
          label: "Fees Schedule Configurables",
          icon: <Description />,
          to: getRegister(URL_FEES_CONFIGURABLES),
          visible: hasConfigurablesPermissions,
        },
        {
          label: "Interest Configurables",
          icon: <Description />,
          to: getRegister(URL_INTEREST_CONFIGURABLES),
          visible: hasConfigurablesPermissions,
        },
        {
          label: "Period Configurables",
          icon: <Description />,
          to: getRegister(URL_PERIOD_CONFIGURABLES),
          visible: hasConfigurablesPermissions,
        },
        {
          label: "Payment Modes Configurables",
          icon: <Description />,
          to: getRegister(URL_PAYMENT_MODES_CONFIGURABLES),
          visible: hasConfigurablesPermissions,
        },
        {
          label: "Supplier Configurables",
          icon: <Description />,
          to: getRegister(URL_SUPPLIER_CONFIGURABLES),
          visible: hasConfigurablesPermissions,
        },
        {
          label: "Allowance Types Configurables",
          icon: <Description />,
          to: getRegister(URL_ALLOWANCE_TYPES_CONFIGURABLES),
          visible: hasConfigurablesPermissions,
        },
      ],
    },

    // OFFICES
    {
      label: "Offices",
      icon: <BusinessOutline />,
      visible: permissions.includes(CAN_EDIT_SYSTEM_SETTINGS),
      children: [
        {
          label: "Departments",
          icon: <HomeOutline />,
          to: getRegister(URL_DEPARTMENTS),
        },
      ],
    },
    //   Master Data
    {
      label: "Master Data",
      icon: <NavigateOutline />,
      visible: permissions.includes(
        CAN_VIEW_SYSTEM_SETTINGS,
        CAN_EDIT_SYSTEM_SETTINGS,
      ),
      children: [
        {
          label: "Add Master Data",
          icon: <Add />,
          visible: true,
          to: getForm(URL_MASTER_DATA),
        },
        {
          label: "Districts",
          icon: <LocationOnOutlined />,
          to: getRegister(URL_DISTRICTS),
        },
        {
          label: "Counties",
          icon: <LocationOnOutlined />,
          to: getRegister(URL_COUNTIES),
        },
        {
          label: "SubCounties",
          icon: <LocationOnOutlined />,
          to: getRegister(URL_SUB_COUNTIES),
        },
        {
          label: "Parishes",
          icon: <LocationOnOutlined />,
          to: getRegister(URL_PARISHES),
        },
        {
          label: "Villages",
          icon: <LocationOnOutlined />,
          to: getRegister(URL_VILLAGES),
        },
      ],
    },
    // BILLINGS
    {
      label: "Fees",
      icon: <CardOutline />,
      visible: permissions.includes(CAN_EDIT_SYSTEM_SETTINGS),
      children: [createAddOption(URL_FEES), createRegisterOption(URL_FEES)],
    },
    // AUDIT_ACTIVITY
    {
      label: "Audit Activity",
      icon: <ShieldCheckmarkOutline />,
      to: getRegister(URL_AUDIT_ACTIVITY),
      visible: permissions.includes(
        CAN_VIEW_SYSTEM_SETTINGS,
        CAN_EDIT_SYSTEM_SETTINGS,
      ),
    },
    // ORGANISATIONS
    {
      label: "Organisations",
      icon: <PeopleOutline />,
      visible: permissions.includes(CAN_ADD_ORGANISATION),
      children: [
        {
          label: "Add",
          icon: <Add />,
          to: getForm(URL_ORGANISATIONS),
        },
        {
          label: "Organisation Register",
          icon: <ListSharp />,
          to: getRegister(URL_ORGANISATIONS),
        },
      ],
    },
  ];
};
