// GENERAL
export const API_CONFIGURABLE = "configurables/";

// USERS MANAGEMENT
export const API_PROFILE = "accounts/users/1";
export const API_USERS = "accounts/users/";
export const API_REGISTER_USER = "accounts/register-user/";
export const API_LOGIN = "token/";
export const API_RESET_PASSWORD = "password_reset/confirm/";
export const API_VERIFY_PASSWORD_RESET_TOKEN = "password_reset/validate_token/";
export const API_REQUEST_PASSWORD_RESET = "password_reset/";
export const API_ACCOUNTS_ROLES = "accounts/roles/";
export const API_ACCOUNTS_PROFILES = "accounts/profiles/";
export const API_CHANGE_PASSWORD = "accounts/users/change-password/";
export const API_FIELD_OFFICERS = "/loan-applications/field-officers/";

//LOAN APPLICATIONS
export const API_LOAN_APPLICATIONS = "loan-applications/";
export const API_LOAN_APPLICATIONS_DRAFTS = "loan-applications/drafts/";
export const API_LOAN_APPLICATIONS_RESCHEDULE =
  "loan-applications/reschedules/";
// INVENTORY MANAGEMENT
export const API_PURCHASE_ORDERS = "purchase-orders/";
export const API_BULK_PURCHASE_ORDERS = "purchase-orders/bulk-upload/";
export const API_BULK_PURCHASE_ORDERS_TEMPLATE =
  "order-inventories/batch-products-upload-template/";
export const API_PURCHASE_ORDER_ITEMS = "order-inventories/";
export const API_INVENTORY = "inventories/";
export const API_INVENTORY_ALL = "inventories/all/";
export const API_PRODUCTS = "products/";

// PAYROLL MANAGEMENT
export const API_PAYROLL = "payrolls/";

//LOCATION DETAILS
export const API_DISTRICTS = "all-districts/";
export const API_COUNTIES = "all-counties/";
export const API_SUB_COUNTIES = "all-sub-counties/";
export const API_PARISHES = "all-parishes/";
export const API_VILLAGES = "all-villages/";
export const API_REGIONS = "all-regions/";

export const API_DISTRICTS_PAGINATED = "districts/";
export const API_COUNTIES_PAGINATED = "counties/";
export const API_SUB_COUNTIES_PAGINATED = "sub-counties/";
export const API_PARISHES_PAGINATED = "parishes/";
export const API_VILLAGES_PAGINATED = "villages/";
export const API_REGIONS_PAGINATED = "regions/";

//CONFIGURABLES
export const API_CONFIGURABLES = "configurables/other/";
export const API_INTEREST_CONFIGURABLES =
  "configurables/interest-configurations/";
export const API_PERIOD_CONFIGURABLES =
  "configurables/loan-validity-period-configurations/";
export const API_FEES_CONFIGURABLES =
  "configurables/fees-schedule-configurations/";
export const API_CHARTS_OF_ACCOUNTS = "accountings/charts/";
export const API_CHARTS_OF_ACCOUNTS_ALL = "accountings/charts/all/";
export const API_SUPPLIER_CONFIGURABLES = "configurables/suppliers/";
export const API_PAYMENT_MODES_CONFIGURABLES = "payments/payment-modes/";
export const API_ALLOWANCE_TYPE_CONFIGURABLES =
  "configurables/allowance-types/";

//VALIDATE NIN
export const VALIDATE_NIN_API = `accounts/register-user/validate-nin/`;

// AUDIT ACTIVITY
export const API_AUDIT_ACTIVITY = "audits/activity/";

// OFFICES
export const API_DEPARTMENTS = "departments/";

// ORGANISATIONS
export const API_ORGANISATIONS = "organisations/";
// Accounts
export const API_LEDGERS = "accountings/ledgers/";

// Expenses
export const API_EXPENSES = "expenses/";

// Requisitions
export const API_REQUISITIONS = "requisitions/";

// Other Income
export const API_OTHER_INCOME = "other-incomes/";
// Payments
export const API_INVOICES = "billings/invoices/";
export const API_PRNS = "payments/prns/";
export const API_MOCK_PAYMENTS = "payments/payments/mock-invoice-payment/";
// REPORTS
// REPORTS
export const API_REPORTS_DASHBOARD = "reports/dashboard/";
export const API_BILLINGS_INVOICE_LIST_REPORT =
  "billings/instalments/invoice-list-report/";
export const API_LOAN_BOOK_REPORT = "reports/loan-book-report/";
export const API_EXPENSES_REPORT = "reports/expenses/";

// PERMISSIONS
export const API_ACCOUNTS_PERMISSIONS = "accounts/permissions/";

// BILLINGS
export const API_BILLINGS_FEES = "billings/fees/";
export const API_BILLING_INSTALMENTS = "billings/instalments/";
export const API_PAYMENTS = "payments/payments/";
export const API_PAYMENT_METHODS = "payments/payment-modes/";

// Exports
export const API_LEDGERS_EXPORT = "accountings/ledgers/excel-export/";
export const API_FINES_EXPORT = "reports/fines/";
// Reports Exports
export const API_CASH_FLOW_EXPORT = "reports/cash-flow-statement-excel/";
export const API_FINANCIAL_POSITION_EXPORT =
  "reports/financial-position-excel/";
export const API_PROFIT_AND_LOSS_EXPORT =
  "/reports/profit-and-loss-statement-excel/";
export const API_LOAN_APPLICATIONS_REPORT = "reports/loan-applications-report/";
export const API_LOAN_APPLICATIONS_REPORT_EXPORT =
  "reports/loan-applications-report/excel-export/";
