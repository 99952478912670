import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Loader from "../common/Loader";
import { guestRoutes, standardRoutes } from "../../utils/routes";
import { DashboardLayout } from "../layouts/DashboardLayout";
import { primaryColor } from "../styled/Theme";
import { useIdleTimer } from "react-idle-timer";
import { IDLE_TIMEOUT } from "../../utils/constants";

const MainNavigator = () => {
  document.documentElement.style.setProperty("--selected-color", primaryColor);

  const { loggedIn, gettingAuth, user, logout } = useAuth();

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: () => {
      if (loggedIn) {
        logout();
      }
    },
  });

  if (gettingAuth) return <Loader />;

  if (loggedIn) {
    return (
      <DashboardLayout>
        <Suspense fallback={<Loader />}>
          <Routes>
            {standardRoutes
              .filter((route) => {
                if (user?.must_change_password) {
                  return route.path === "/change-password";
                }
                return true;
              })
              .map(({ component, path }, i) => (
                <Route path={path} element={component} key={i} />
              ))}
            <Route
              path="*"
              element={
                <Navigate
                  to={user?.must_change_password ? "/change-password" : "/"}
                />
              }
            />
          </Routes>
        </Suspense>
      </DashboardLayout>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {guestRoutes.map(({ component, path }, i) => (
          <Route path={path} element={component} key={i} />
        ))}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default MainNavigator;
