import { Popover } from "antd";
import { ItemWrapper } from "./Elements";
import { IconButton } from "@mui/material";
import {
  CircleOutlined,
  Create,
  MoreVert,
  Visibility,
  Delete,
  PowerSettingsNew,
  Close,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import {
  ACTIVATE,
  CLOSE,
  DEACTIVATE,
  DELETE,
  EDIT,
  VIEW,
} from "../../utils/constants";
import useSet from "../../hooks/common/useSet";
import { useToasts } from "../../hooks/common/useToasts";

type TActionProps = {
  label: string;
  to?: string;
  icon?: any;
  onClick?: () => void;
  deleteMessage?: string;
  deleteUrl?: string;
  custom?: boolean;
  handleCustom?: () => void;
};
type TFormActionsProps = {
  actions: TActionProps[];
};

function getIcon(label: string) {
  switch (label) {
    case VIEW:
      return <Visibility fontSize="small" />;
    case EDIT:
      return <Create fontSize="small" />;
    case DELETE:
      return <Delete fontSize="small" color="error" />;
    case ACTIVATE:
      return <PowerSettingsNew fontSize="small" color="success" />;
    case DEACTIVATE:
      return <PowerSettingsNew fontSize="small" color="error" />;
    case CLOSE:
      return <Close fontSize="small" color="warning" />;
    default:
      return <CircleOutlined sx={{ fontSize: 8, width: 20 }} />;
  }
}

const handleDelete = (
  deleteUrl: string,
  deleteMessage: string,
  onDelete: any,
) => {
  Modal.confirm({
    title: "Confirm Delete",
    content: deleteMessage,
    okText: "Yes, Delete",
    okType: "danger",
    cancelText: "Cancel",
    onOk: async () => {
      onDelete({ api: deleteUrl, method: "DELETE" });
    },
  });
};

const handleDeactivate = (
  deleteUrl: string,
  deleteMessage: string,
  onDelete: any,
) => {
  Modal.confirm({
    title: "Confirm Deactivate",
    content: deleteMessage,
    okText: "Yes, Deactivate",
    okType: "danger",
    cancelText: "Cancel",
    onOk: async () => {
      onDelete({
        api: deleteUrl,
        method: "PATCH",
        params: { active: false },
      });
    },
  });
};

const handleActivate = (
  deleteUrl: string,
  deleteMessage: string,
  onDelete: any,
) => {
  Modal.confirm({
    title: "Confirm Activate",
    content: deleteMessage,
    okText: "Yes, Activate",
    cancelText: "Cancel",
    onOk: async () => {
      onDelete({
        api: deleteUrl,
        method: "PATCH",
        params: { active: true },
      });
    },
  });
};

const handleClose = (
  deleteUrl: string,
  deleteMessage: string,
  onDelete: any,
) => {
  Modal.confirm({
    title: "Confirm Close",
    content: deleteMessage,
    okText: "Yes, Close",
    okType: "danger",
    cancelText: "Cancel",
    onOk: async () => {
      onDelete({ api: deleteUrl, method: "POST" });
    },
  });
};

export const ActionsMenu = (actions: any[]) => {
  const { uploadData: onDelete, success, error } = useSet();

  useToasts({
    success,
    error,
    redirect: "reload",
    successMessage: "Operation Successfull",
  });

  return (
    <>
      {actions?.map(
        (
          {
            label,
            to,
            onClick,
            icon: Icon,
            deleteMessage = "",
            deleteUrl = "",
            custom = false,
            handleCustom = () => {},
          }: TActionProps,
          key: number,
        ) => (
          <Link
            to={to || ""}
            onClick={
              custom
                ? () => handleCustom && handleCustom()
                : label === DELETE
                  ? () => handleDelete(deleteUrl, deleteMessage, onDelete)
                  : label === DEACTIVATE
                    ? () => handleDeactivate(deleteUrl, deleteMessage, onDelete)
                    : label === ACTIVATE
                      ? () => handleActivate(deleteUrl, deleteMessage, onDelete)
                      : label === CLOSE
                        ? () => handleClose(deleteUrl, deleteMessage, onDelete)
                        : onClick
            }
            key={key}
          >
            <ItemWrapper>
              {(Icon && <Icon />) || getIcon(label)}
              {label}
            </ItemWrapper>
          </Link>
        ),
      )}
    </>
  );
};

export const FormActions = ({ actions }: TFormActionsProps) => {
  return (
    <Popover
      content={<>{ActionsMenu(actions)}</>}
      trigger="click"
      overlayStyle={{ minWidth: 150 }}
      overlayInnerStyle={{ padding: 5 }}
      placement="left"
    >
      <IconButton size="small">
        <MoreVert />
      </IconButton>
    </Popover>
  );
};
