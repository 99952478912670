import { useEffect, useState } from "react";
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import SelectInput from "./SelectInput";
import { GenericObjectArrayType, TOption } from "../../types/common";
import { TableFilterProps } from "../../types/table";
import FilterForm from "../pages/reports/FilterForm";
import { openModal } from "../../utils/modalUtils";
import { SettingsOutline } from "@raresail/react-ionicons";

const { RangePicker } = DatePicker;

export const TableFilter = (props: TableFilterProps) => {
  const {
    name,
    onChange,
    updateFilterValues,
    filters,
    type,
    selectOptions,
    showHeaders = false,
    header,
  } = props;
  const [options, setOptions] = useState<TOption[]>([]);

  useEffect(() => {
    if (selectOptions) {
      const options = selectOptions || [];
      setOptions(
        options.map((sub: GenericObjectArrayType) => ({
          value: sub.id,
          label: sub.name,
        })),
      );
    }
  }, [selectOptions]);

  function handleFilter() {
    openModal({
      title: "Select filter",
      type: "form",
      formId: "filter-form",
      body: (
        <FilterForm
          filterOptions={{
            startDateKey: `${name}_min_date`,
            endDateKey: `${name}_max_date`,
          }}
        />
      ),
      okText: "Apply",
    })
      .then(({ currValue, stringValue }: any) => {
        updateFilterValues({ ...currValue, stringValue });
      })
      .catch(() => {});
  }

  const TitleText = () => {
    return (
      <div className="capitalize font12 mb-1">Filter by {header || name} </div>
    );
  };

  if (type === "select")
    return (
      <div>
        {showHeaders && <TitleText />}
        <SelectInput
          options={options}
          name={name}
          value={
            isNaN(filters?.[name] as any)
              ? filters?.[name]
              : Number(filters?.[name])
          }
          onChange={(value) => onChange(name, value)}
          label={""}
          // placeholder={`Search ${header || name}`}
          error={{}}
        />
      </div>
    );

  if (type === "date") {
    return (
      <div>
        {showHeaders && <TitleText />}
        <RangePicker
          onChange={(value: any) => {
            updateFilterValues({
              [`${name}_after`]: value?.[0].format("YYYY-MM-DD") || null,
              [`${name}_before`]: value?.[1].format("YYYY-MM-DD") || null,
            });
          }}
          value={[
            filters?.[`${name}_after`]
              ? dayjs(filters?.[`${name}_after`])
              : null,
            filters?.[`${name}_before`]
              ? dayjs(filters?.[`${name}_before`])
              : null,
          ]}
          style={{ height: 40 }}
        />
      </div>
    );
  }
  if (type === "date_select") {
    return (
      <div>
        {showHeaders && <TitleText />}
        <Button onClick={handleFilter}>
          <SettingsOutline />
          Filter
        </Button>
      </div>
    );
  }
  return (
    <div>
      {showHeaders && <TitleText />}
      <input
        type="text"
        className="form-control"
        name={name}
        onBlur={(event) => onChange(name, event.target.value)}
        style={{ fontSize: 13 }}
        placeholder={showHeaders ? `Search ${header || name}` : ""}
      />
    </div>
  );
};
