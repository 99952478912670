import { useState } from "react";
import SelectInput from "../../common/SelectInput";
import { formatDate, getPredefinedFilters } from "../../../utils/adhocUtils";
import { Visibility } from "../../common/Visibility";
import { DateInput } from "../../common/DateInput";
import {
  END_DATE_FILTER,
  START_DATE_FILTER,
  preFilters,
} from "../../../utils/constants";

const FilterForm = (props) => {
  const { formId, proceed, filterOptions } = props;
  let { startDateKey, endDateKey } = filterOptions;
  if (!startDateKey) {
    startDateKey = START_DATE_FILTER;
  }
  if (!endDateKey) {
    endDateKey = END_DATE_FILTER;
  }

  const [currValue, setValue] = useState("today");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const options = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "this_month", label: "This Month" },
    { value: "last_month", label: "Last Month" },
    { value: "this_year", label: "This Year" },
    { value: "last_year", label: "Last Year" },
    { value: "this_fy", label: "This F/Y" },
    { value: "last_fy", label: "Last F/Y" },
    { value: "custom", label: "Custom" },
  ];

  const onSubmit = (event) => {
    event.preventDefault();
    const filter = {};

    if (preFilters.includes(currValue)) {
      const [start, end] = getPredefinedFilters(currValue);
      filter[startDateKey] = start;
      filter[endDateKey] = end;
    } else {
      if (startDate) {
        filter[startDateKey] = formatDate(new Date(startDate));
      } else {
        filter[startDateKey] = "";
      }

      if (endDate) {
        filter[endDateKey] = formatDate(new Date(endDate));
      } else {
        filter[endDateKey] = "";
      }
    }

    proceed({
      currValue: filter,
      stringValue: `${filter[startDateKey]} - ${filter[endDateKey]}`,
    });
  };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="row">
        <SelectInput
          options={options.map((sup) => ({
            label: sup.label,
            value: sup.value,
          }))}
          name="filter"
          label="Filter"
          onChange={(value: any) => {
            setValue(value);
          }}
          // placeholder="Select module"
          value={currValue}
          required
        />
      </div>
      <Visibility show={currValue === "custom"}>
        <div className="row">
          <div className="col-sm-6">
            <DateInput
              value={startDate}
              label="Start date"
              onChange={(value) => setStartDate(value)}
              name="end_date"
              // placeholder="Choose start date"
            />
          </div>

          <div className="col-sm-6">
            <DateInput
              value={endDate}
              label=""
              onChange={(value) => setEndDate(value)}
              name="end_date"
            />
          </div>
        </div>
      </Visibility>
    </form>
  );
};

export default FilterForm;
