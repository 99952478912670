import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { beautify } from "../../utils/helpers";

type TToastsProps = {
  id?: any;
  error: any;
  success: any;
  redirect?: any;
  callback?: any;
  submitResponse?: any;
  source?: string;
  submitting?: boolean;
  setSubmited?: any;
  successMessage?: string;
};

export const useToasts = ({
  success,
  error,
  redirect,
  callback,
  id,
  submitResponse,
  source,
  submitting,
  successMessage,
}: TToastsProps) => {
  const navigate = useNavigate();
  // handle notification
  useEffect(() => {
    if (success) {
      toast.success(
        successMessage ||
          `You have successfully ${id ? "updated" : "created"} ${beautify(source)}`,
      );
      // redirect to route
      if (redirect) {
        if (redirect === "reload") {
          navigate(0);
        } else {
          navigate(redirect);
        }
      }
      // callback method incase of any other jobs after the submit
      if (callback) callback(submitResponse);
    }

    if (error && Object.keys(error).length > 0 && !error?.notSubmit) {
      const displayError = (err: any, key: string = "") => {
        if (typeof err === "string") {
          toast.error(key ? `${key}: ${err}` : err);
        } else if (Array.isArray(err)) {
          err.forEach((e) => displayError(e, key));
        } else if (typeof err === "object") {
          Object.entries(err).forEach(([k, v]) => displayError(v, k));
        }
      };

      displayError(error || `Failed to submit ${beautify(source) || "data"}`);

      if (error?.non_field_errors) {
        toast.error(error?.non_field_errors[0]);
      }
    }
  }, [success, error, submitting]);
  return {};
};
