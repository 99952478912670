import React from "react";
import { LogoutRoute } from "../../../context/AuthContext";
import ChartsOfAccountsReport from "../reports/ChartsOfAccountsReport";
import { URL_LOAN_REPAYMENTS_REPORT } from "../../../utils/urls";
const ApplicationDetailView = React.lazy(
  () => import("../../../hooks/loan_applications/ApplicationDetailView"),
);
const GenericViewer = React.lazy(() => import("../../layouts/GenericViewer"));
const InventoryDetailView = React.lazy(
  () => import("../../../hooks/inventory/InventoryDetailView"),
);
const AdhocReports = React.lazy(
  () => import("../../pages/reports/AdhocReports"),
);
const BalanceSheetReports = React.lazy(
  () => import("../../pages/reports/BalanceSheetReports"),
);
const TransactionsHistoryTable = React.lazy(
  () => import("../../pages/reports/TransactionsHistoryTable"),
);
const AgingReports = React.lazy(
  () => import("../../pages/reports/AgingReports"),
);
const TransactionsReport = React.lazy(
  () => import("../../pages/reports/TransactionsReport"),
);
const PaymentsReport = React.lazy(
  () => import("../../pages/reports/PaymentsReport"),
);
const ImpoundedBikesReport = React.lazy(
  () => import("../../pages/reports/ImpoundedBikesReport"),
);
const LoanApplicationsReport = React.lazy(
  () => import("../../pages/reports/LoanApplicationsReport"),
);
const FinesReport = React.lazy(() => import("../../pages/reports/FinesReport"));
const LoanBookReport = React.lazy(
  () => import("../../pages/reports/LoanBookReport"),
);
const InvoiceListReport = React.lazy(
  () => import("../../pages/reports/InvoiceListReport"),
);
const ExpensesReport = React.lazy(
  () => import("../../pages/reports/ExpensesReport"),
);
const SettingsPage = React.lazy(() => import("../settings/SettingsPage"));
const DashboardPage = React.lazy(() => import("./DashboardPage"));
const GenericForm = React.lazy(() => import("../../layouts/GenericForm"));
const GenericRegister = React.lazy(
  () => import("../../layouts/GenericRegister"),
);

const DashboardRoutes = [
  {
    path: "/",
    component: <DashboardPage />,
    exact: true,
  },
  {
    path: "/register/:id",
    component: <GenericRegister />,
    exact: true,
  },
  {
    path: "/register/:id/:status",
    component: <GenericRegister />,
    exact: true,
  },
  {
    path: "/:source_id/create",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/:source_id/create/:parent_id",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/:source_id/drafts/:draft_id",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/:source_id/update/:id",
    component: <GenericForm />,
    exact: true,
  },
  {
    path: "/loan_application/view/:id",
    component: <ApplicationDetailView />,
    exact: true,
  },
  {
    path: "/inventory/view/:id",
    component: <InventoryDetailView />,
    exact: true,
  },
  {
    path: "/:source_id/view/:id",
    component: <GenericViewer />,
    exact: true,
  },
  {
    path: "/settings",
    component: <SettingsPage />,
    exact: true,
  },

  // Reports
  {
    path: `/reports/adhoc`,
    component: <AdhocReports />,
    exact: true,
  },
  {
    path: `/reports/balance-sheet`,
    component: <BalanceSheetReports />,
    exact: true,
  },
  {
    path: `/reports/debtors-ageing-report`,
    component: <AgingReports />,
    exact: true,
  },
  {
    path: `/reports/transactions`,
    component: <TransactionsReport />,
    exact: true,
  },
  {
    path: `/reports/payments`,
    component: <PaymentsReport />,
    exact: true,
  },
  {
    path: URL_LOAN_REPAYMENTS_REPORT,
    component: <PaymentsReport is_loan_repayment={true} />,
    exact: true,
  },
  {
    path: `/reports/impounded-bikes`,
    component: <ImpoundedBikesReport />,
    exact: true,
  },
  {
    path: `/reports/loan-book`,
    component: <LoanBookReport />,
    exact: true,
  },
  {
    path: `/reports/charts-of-accounts`,
    component: <ChartsOfAccountsReport />,
    exact: true,
  },
  {
    path: `/reports/invoice-list`,
    component: <InvoiceListReport />,
    exact: true,
  },
  {
    path: `/reports/expenses`,
    component: <ExpensesReport />,
    exact: true,
  },
  {
    path: `/reports/fines`,
    component: <FinesReport />,
    exact: true,
  },
  {
    path: `/reports/loan-applications-report`,
    component: <LoanApplicationsReport />,
    exact: true,
  },

  // Transactions History
  {
    path: `/transactions/:chart_id`,
    component: <TransactionsHistoryTable />,
    exact: true,
  },

  // Logout
  {
    path: "/logout",
    component: <LogoutRoute />,
    exact: true,
  },
];

export default DashboardRoutes;
