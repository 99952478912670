import { Select } from "antd";
import { TError, TOption } from "../../types/common";

type SelectInputType = {
  onChange?(value: number, option: TOption | TOption[]): void;
  label?: string;
  value: number | string | null;
  name: string;
  options: TOption[];
  error?: TError;
  multiple?: "multiple" | "tags";
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
  acceptNewItems?: boolean;
  setNotFoundOption?: (value: boolean) => void;
  placeholder?: string | React.ReactNode;
};

const SelectInput = (props: SelectInputType) => {
  const {
    onChange,
    value,
    options,
    multiple,
    disabled,
    loading = false,
    setNotFoundOption = () => {},
    acceptNewItems = false,
    placeholder = "",
    ...rest
  } = props;

  const handleSearch = (newValue: string) => {
    if (acceptNewItems) {
      const isSearchValueInState = options.some(
        (option) => option.value === newValue,
      );
      if (!isSearchValueInState || newValue === "") {
        setNotFoundOption(true);
      } else {
        setNotFoundOption(false);
      }
    }
  };

  return (
    <div>
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        onChange={onChange}
        value={value as number}
        filterOption={(input, option) =>
          String(option?.label ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        style={{
          display: "block",
          width: "100%",
          height: 36,
          fontWeight: 500,
        }}
        options={options}
        mode={multiple && "multiple"}
        disabled={disabled}
        size="middle"
        loading={loading}
        getPopupContainer={(node) => node.parentNode}
        onSearch={handleSearch}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default SelectInput;
