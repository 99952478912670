import styled from "styled-components";
import { Menu, Popover } from "antd";
import { alpha, IconButton } from "@mui/material";
import { primaryColor } from "../styled/Theme";
import { DocumentTextOutline } from "@raresail/react-ionicons";
import {
  URL_LEDGER_REPORT,
  URL_LOAN_APPLICATIONS_REPORT,
  URL_LOAN_REPAYMENTS_REPORT,
} from "../../utils/urls";
import { ActionsMenu } from "../common/FormActions";
export const reportSettings = [
  {
    label: "Financial Reports",
    icon: DocumentTextOutline,
    to: "/reports/balance-sheet",
  },
  {
    label: "Adhoc Reports",
    icon: DocumentTextOutline,
    to: "/reports/adhoc",
  },
  {
    label: "Debtors Aging Reports",
    icon: DocumentTextOutline,
    to: "/reports/debtors-ageing-report",
  },
  {
    label: "Transactions Report",
    icon: DocumentTextOutline,
    to: "/reports/transactions",
  },
  {
    label: "Charts of Accounts Report",
    icon: DocumentTextOutline,
    to: "/reports/charts-of-accounts",
  },
  {
    label: "Invoice List Report",
    icon: DocumentTextOutline,
    to: "/reports/invoice-list",
  },
  {
    label: "Payments Report",
    icon: DocumentTextOutline,
    to: "/reports/payments",
  },
  {
    label: "Loan Book Report",
    icon: DocumentTextOutline,
    to: "/reports/loan-book",
  },
  {
    label: "Loan Repayments Report",
    icon: DocumentTextOutline,
    to: URL_LOAN_REPAYMENTS_REPORT,
  },
  {
    label: "Loan Applications Report",
    icon: DocumentTextOutline,
    to: URL_LOAN_APPLICATIONS_REPORT,
  },
  {
    label: "Ledger Report",
    icon: DocumentTextOutline,
    to: URL_LEDGER_REPORT,
  },
  {
    label: "Expenses Report",
    icon: DocumentTextOutline,
    to: "/reports/expenses",
  },
  {
    label: "Fines Report",
    icon: DocumentTextOutline,
    to: "/reports/fines",
  },
  {
    label: "Impounded Bikes Report",
    icon: DocumentTextOutline,
    to: "/reports/impounded-bikes",
  },
];
const ReportsDropdown = () => {
  return (
    <>
      <Popover
        content={
          <Menu mode="inline" className="font13 rightmenu">
            {ActionsMenu(
              reportSettings.sort((a, b) => a.label.localeCompare(b.label)),
            )}
          </Menu>
        }
        trigger="click"
        overlayStyle={{ minWidth: 200 }}
        overlayInnerStyle={{ padding: 5 }}
        placement="bottomRight"
      >
        <AvatarWrapper className="animate">
          <IconButton
            sx={{
              bgcolor: alpha(primaryColor, 0.1),
              borderRadius: 10,
              padding: "0.5rem 1rem",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              color: "#555",
            }}
          >
            <DocumentTextOutline className="font13" />
            <span className="font13">Reports</span>
          </IconButton>
        </AvatarWrapper>
      </Popover>
    </>
  );
};

export default ReportsDropdown;

const AvatarWrapper = styled.div`
  &:hover {
    scale: 1.03;
  }
`;
